import {
  fork,
  takeEvery,
  takeLatest,
  all,
  select,
  call,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/Note/actions';
import { getQueryParameters } from 'Modules/App/selectors';
import { getNoteByEventId } from 'Modules/Note/selectors';
import { pushQueryParameters } from 'Utils/history';
import handleFetchAllNotes from 'Modules/Note/sagas/handleFetchAllNotes';
import handleFetchNote from 'Modules/Note/sagas/handleFetchNote';
import handleDeleteNote from 'Modules/Note/sagas/handleDeleteNote';
import handleSaveNote from 'Modules/Note/sagas/handleSaveNote';
import handleAssociateNoteToEvent from 'Modules/Note/sagas/handleAssociateNoteToEvent';
import handleLogEventToSalesforce from 'Modules/Note/sagas/handleLogEventToSalesforce';
import analyticsWatchers from 'Modules/Note/sagas/analytics';

function* watchFetchAllNotes() {
  yield takeEvery(actionTypes.FETCH_ALL_NOTES.BEGIN, handleFetchAllNotes);
}

function* watchFetchNote() {
  yield takeEvery(actionTypes.FETCH_NOTE.BEGIN, handleFetchNote);
}

function* watchSaveNote() {
  yield takeLatest(actionTypes.SAVE_NOTE.BEGIN, handleSaveNote);
}

function* watchDeleteNote() {
  yield takeLatest(actionTypes.DELETE_NOTE.BEGIN, handleDeleteNote);
}

function* watchAssociateNoteToEvent() {
  yield takeEvery(actionTypes.ASSOCIATE_NOTE_TO_EVENT.BEGIN, handleAssociateNoteToEvent);
}

function* watchLogEventToSalesforce() {
  yield takeEvery(actionTypes.LOG_EVENT_TO_SALESFORCE.BEGIN, handleLogEventToSalesforce);
}

export function* upstart() {
  yield call(handleFetchAllNotes);

  const queryParameters = yield select(getQueryParameters);

  // if noteId does not exist but eventId does, the user must have
  // opened this page directly from gcal, so we need to check if
  // this event is already associated with a note
  if (queryParameters.get('eventId') && !queryParameters.get('noteId')) {
    const existingNote = yield select(state => getNoteByEventId(state, { event: { iCalUID: queryParameters.get('eventId') } }));
    let params = queryParameters;
    if (existingNote) {
      params = queryParameters.set('noteId', existingNote.id).toJS();
    } else {
      params = queryParameters.set('noteId', 'new').toJS();
    }
    yield call(pushQueryParameters, params);
    yield fork(handleFetchNote);
  } else if (queryParameters.get('noteId')) {
    yield fork(handleFetchNote);
  }
}

export default function* root() {
  yield all([
    fork(watchFetchAllNotes),
    fork(watchFetchNote),
    fork(watchSaveNote),
    fork(watchDeleteNote),
    fork(watchAssociateNoteToEvent),
    fork(watchLogEventToSalesforce),
    fork(analyticsWatchers),
  ]);
}
