import React from 'react';

/* eslint-disable max-len */
export default () => (
  <svg
    width="241"
    height="151"
    viewBox="0 0 241 151"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.03795 0.871976471 44.7601722 0.871976471 44.7601722 21.5781176 0.03795 21.5781176"
      />
      <path
        d="M11.4197143,7.83 C10.7652857,8.9375 10.3962857,10.0075 8.64642857,10.6625 L8.64642857,10.6625 C7.86985714,10.9525 5.45528571,11.2325 4.53471429,8.96 L4.53471429,8.96 C3.91757143,7.43875 4.275,5.31375 5.87957143,4.47375 L5.87957143,4.47375 C7.75414286,3.49125 10.2831429,5.03875 11.3348571,6.90125 L11.3348571,6.90125 L13.2145714,3.7725 C12.942,3.43125 10.6225714,0.585 6.73714286,0.9075 L6.73714286,0.9075 C4.887,1.0625 3.17185714,1.99625 2.01471429,3.36875 L2.01471429,3.36875 C0.835714286,4.765 0.300857143,6.58625 0.550285714,8.35625 L0.550285714,8.35625 C0.793285714,10.09375 1.78714286,11.69375 3.26314286,12.7525 L3.26314286,12.7525 C4.87157143,13.9075 7.09071429,14.40875 9.06557143,13.95875 L9.06557143,13.95875 C11.2294286,13.4625 12.4405714,12.25375 13.5295714,10.80875 L13.5295714,10.80875 C15.8412857,7.74625 16.569,4.55375 18.8897143,4.16 L18.8897143,4.16 C19.7267143,4.0175 20.5778571,4.1275 21.285,4.5775 L21.285,4.5775 C21.9947143,5.03 22.4318571,5.765 22.608,6.545 L22.608,6.545 C22.9705714,8.16 22.3842857,10.14125 20.5868571,10.7175 L20.5868571,10.7175 C19.7665714,10.97875 18.7894286,10.955 18.027,10.56125 L18.027,10.56125 C16.9418571,10.00125 15.9634286,8.06875 15.9634286,8.06875 L15.9634286,8.06875 L13.9307143,11.32125 C13.95,11.46 16.5522857,15.16625 21.3235714,13.87375 L21.3235714,13.87375 C23.094,13.3925 24.6497143,12.29375 25.5728571,10.79 L25.5728571,10.79 C26.5268571,9.23625 26.766,7.34 26.2285714,5.6175 L26.2285714,5.6175 C25.7065714,3.95 24.4787143,2.51625 22.8651429,1.68125 L22.8651429,1.68125 C21.8391429,1.15 20.6768571,0.90125 19.5094286,0.87125 L19.5094286,0.87125 C19.44,0.86875 19.3705714,0.86875 19.3011429,0.86875 L19.3011429,0.86875 C14.8204286,0.86875 13.2068571,4.81125 11.4197143,7.83 L11.4197143,7.83 Z"
        id="path-3"
      />
    </defs>
    <g
      id="Meeting-Notes"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Meeting-notes---not-enabled"
        transform="translate(-520 -296)"
      >
        <g
          id="Group-5"
          transform="translate(521 298)"
        >
          <g
            id="Group-8"
            transform="translate(1)"
          >
            <path
              d="M206.281139,34.6470588 L5.71757576,34.6470588 C2.55941818,34.6470588 0,32.0678824 0,28.8869412 L0,6.76011765 C0,3.57917647 2.55941818,1 5.71757576,1 L206.281139,1 C209.439297,1 212,3.57917647 212,6.76011765 L212,28.8869412 C212,32.0678824 209.439297,34.6470588 206.281139,34.6470588"
              id="Fill-1"
              fill="#D2EDF1"
            />
            <path
              d="M204.290781,131.577859 L7.70896242,131.577859 C3.46896242,131.577859 -0.000128484849,128.083741 -0.000128484849,123.813153 L-0.000128484849,8.63668235 C-0.000128484849,4.36609412 3.46896242,0.871976471 7.70896242,0.871976471 L204.290781,0.871976471 C208.530781,0.871976471 211.999872,4.36609412 211.999872,8.63668235 L211.999872,123.813153 C211.999872,128.083741 208.530781,131.577859 204.290781,131.577859 Z"
              id="Stroke-3"
              stroke="#04A2BA"
              strokeWidth="4"
            />
            <polygon
              id="Fill-5"
              fill="#D2EDF1"
              points="19.953697 117.342824 80.3415758 117.342824 80.3415758 50.0487059 19.953697 50.0487059"
            />
            <path
              d="M63.63816,75.9308 C63.63816,83.7925647 57.3102812,90.1660941 49.5048267,90.1660941 C41.6993721,90.1660941 35.3714933,83.7925647 35.3714933,75.9308 C35.3714933,68.0690353 41.6993721,61.6955059 49.5048267,61.6955059 C57.3102812,61.6955059 63.63816,68.0690353 63.63816,75.9308"
              id="Fill-7"
              fill="#58BECA"
            />
          </g>
          <g
            id="Group-11"
            transform="translate(28 95)"
          >
            <mask
              id="mask-2"
              fill="#fff"
            >
              <use xlinkHref="#path-1" />
            </mask>
            <path
              d="M44.7601722,17.0484471 C44.7601722,25.9817412 34.7487833,33.2249176 22.3990611,33.2249176 C10.0493389,33.2249176 0.03795,25.9817412 0.03795,17.0484471 C0.03795,8.11515294 10.0493389,0.871976471 22.3990611,0.871976471 C34.7487833,0.871976471 44.7601722,8.11515294 44.7601722,17.0484471"
              id="Fill-9"
              fill="#58BECA"
              mask="url(#mask-2)"
            />
          </g>
          <g
            id="Group-26"
            transform="translate(0 34)"
          >
            <path
              d="M189.289734,30.7019594 L109.469374,30.7019594 C105.574913,30.7019594 102.388536,27.4921938 102.388536,23.5691469 C102.388536,19.6461 105.574913,16.4363344 109.469374,16.4363344 L189.289734,16.4363344 C193.184195,16.4363344 196.370572,19.6461 196.370572,23.5691469 C196.370572,27.4921938 193.184195,30.7019594 189.289734,30.7019594"
              id="Fill-12"
              fill="#D2EDF1"
            />
            <path
              d="M167.271416,58.9418016 L105.856087,58.9418016 C103.948123,58.9418016 102.389051,57.3699891 102.389051,55.4493172 L102.389051,54.7399266 C102.389051,52.8179578 103.948123,51.2461453 105.856087,51.2461453 L167.271416,51.2461453 C169.178093,51.2461453 170.738452,52.8179578 170.738452,54.7399266 L170.738452,55.4493172 C170.738452,57.3699891 169.178093,58.9418016 167.271416,58.9418016"
              id="Fill-14"
              fill="#D2EDF1"
            />
            <path
              d="M193.233632,58.2098453 L176.723692,58.2098453 C174.998542,58.2098453 173.587524,56.7871734 173.587524,55.0480641 L173.587524,54.4061109 C173.587524,52.6695953 174.998542,51.2469234 176.723692,51.2469234 L193.233632,51.2469234 C194.958781,51.2469234 196.371087,52.6695953 196.371087,54.4061109 L196.371087,55.0480641 C196.371087,56.7871734 194.958781,58.2098453 193.233632,58.2098453"
              id="Fill-16"
              fill="#D2EDF1"
            />
            <path
              d="M122.034901,82.5769594 L105.524961,82.5769594 C103.799811,82.5769594 102.388793,81.1542875 102.388793,79.416475 L102.388793,78.7745219 C102.388793,77.0380063 103.799811,75.6153344 105.524961,75.6153344 L122.034901,75.6153344 C123.760051,75.6153344 125.172356,77.0380063 125.172356,78.7745219 L125.172356,79.416475 C125.172356,81.1542875 123.760051,82.5769594 122.034901,82.5769594"
              id="Fill-18"
              fill="#D2EDF1"
            />
            <path
              d="M149.090398,82.5769594 L132.581746,82.5769594 C130.855308,82.5769594 129.44429,81.1542875 129.44429,79.416475 L129.44429,78.7745219 C129.44429,77.0380063 130.855308,75.6153344 132.581746,75.6153344 L149.090398,75.6153344 C150.815548,75.6153344 152.227853,77.0380063 152.227853,78.7745219 L152.227853,79.416475 C152.227853,81.1542875 150.815548,82.5769594 149.090398,82.5769594"
              id="Fill-20"
              fill="#D2EDF1"
            />
            <path
              d="M167.271416,72.1334844 L105.856087,72.1334844 C103.948123,72.1334844 102.389051,70.5616719 102.389051,68.641 L102.389051,67.9316094 C102.389051,66.0096406 103.948123,64.4378281 105.856087,64.4378281 L167.271416,64.4378281 C169.178093,64.4378281 170.738452,66.0096406 170.738452,67.9316094 L170.738452,68.641 C170.738452,70.5616719 169.178093,72.1334844 167.271416,72.1334844"
              id="Fill-22"
              fill="#D2EDF1"
            />
            <path
              d="M0.643583832,0.873834375 L214.356159,0.873834375"
              id="Stroke-24"
              stroke="#04A2BA"
              strokeWidth="4"
            />
          </g>
          <g
            id="Group-29"
            transform="translate(94 9)"
          >
            <mask
              id="mask-4"
              fill="#fff"
            >
              <use xlinkHref="#path-3" />
            </mask>
          </g>
          <g
            id="Group-38"
            transform="translate(163 81)"
          >
            <path
              d="M32.6039224,2.86491765 L0.803922414,57.9878588 C-1.25081897,61.5492706 1.31728448,65.9997412 5.42547414,65.9997412 L69.0267672,65.9997412 C73.1349569,65.9997412 75.7030603,61.5492706 73.648319,57.9878588 L41.848319,2.86491765 C39.7948707,-0.696494118 34.6586638,-0.696494118 32.6039224,2.86491765"
              id="Fill-30"
              fill="#D2EDF1"
            />
            <path
              d="M32.6039224,2.86491765 L0.803922414,57.9878588 C-1.25081897,61.5492706 1.31728448,65.9997412 5.42547414,65.9997412 L69.0267672,65.9997412 C73.1349569,65.9997412 75.7030603,61.5492706 73.648319,57.9878588 L41.848319,2.86491765 C39.7948707,-0.696494118 34.6586638,-0.696494118 32.6039224,2.86491765 Z"
              id="Stroke-32"
              stroke="#04A2BA"
              strokeWidth="4"
            />
            <path
              d="M37.5384052,46.1660941 L37.5384052,46.1660941 C35.7603879,46.1660941 34.3056466,44.7102118 34.3056466,42.9308 L34.3056466,26.1072706 C34.3056466,24.3278588 35.7603879,22.8719765 37.5384052,22.8719765 C39.3164224,22.8719765 40.7711638,24.3278588 40.7711638,26.1072706 L40.7711638,42.9308 C40.7711638,44.7102118 39.3164224,46.1660941 37.5384052,46.1660941"
              id="Fill-34"
              fill="#03A3BB"
            />
            <path
              d="M40.7711638,51.9896235 C40.7711638,53.7768 39.324181,55.2249176 37.5384052,55.2249176 C35.7526293,55.2249176 34.3056466,53.7768 34.3056466,51.9896235 C34.3056466,50.2024471 35.7526293,48.7543294 37.5384052,48.7543294 C39.324181,48.7543294 40.7711638,50.2024471 40.7711638,51.9896235"
              id="Fill-36"
              fill="#03A3BB"
            />
          </g>
          <g
            id="image"
            transform="translate(93 8)"
            fill="#58BECA"
          >
            <path
              d="M15.2025543,0.625616798 C17.0507782,0.625616798 18.6634088,1.67727034 19.5220382,3.23884514 C20.2686321,2.8983727 21.0946488,2.70908136 21.9635661,2.70908136 C25.2973648,2.70908136 28,5.49112861 28,8.92325459 C28,12.3551706 25.2973648,15.1373228 21.9635661,15.1373228 C21.5564733,15.1373228 21.1590512,15.095643 20.7745918,15.0164829 C20.0183272,16.3926509 18.5770932,17.3228346 16.9231052,17.3228346 C16.2307285,17.3228346 15.5759028,17.15979 14.9927838,16.8692913 C14.2262312,18.7097113 12.4398377,20 10.3584897,20 C8.19051748,20 6.34280801,18.600315 5.63407357,16.6371654 C5.32399583,16.704042 5.00270425,16.7393176 4.6733881,16.7393176 C2.09235608,16.7393176 0,14.5817323 0,11.9205249 C0,10.1366929 0.939903881,8.57942257 2.33689981,7.74645669 C2.04924972,7.07076115 1.8894784,6.32566929 1.8894784,5.5423622 C1.8894784,2.48136483 4.32431916,0 7.32777296,0 C9.09112153,0 10.6582796,0.855433071 11.6518864,2.18131234 C12.5548566,1.22120735 13.8121427,0.625616798 15.2025543,0.625616798 Z"
              id="Fill-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
