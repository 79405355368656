import { sagas as grooveUiSagas } from '@groove-labs/groove-ui';

import noteRootSaga, { upstart as noteUpstartSaga } from 'Modules/Note/sagas';
import appRootSaga, { upstart as appUpstartSaga } from 'Modules/App/sagas';
import eventsRootSaga, { upstart as eventsUpstartSaga } from 'Modules/Events/sagas';
import {
  call, fork, all, take,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/App/actions';

const upstartSagas = [
  eventsUpstartSaga,
  noteUpstartSaga,
];


const rootSagas = [
  grooveUiSagas.form,
  eventsRootSaga,
  noteRootSaga,
];

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createRetryableSaga(saga) {
  const runSaga = function* doRunSaga() {
    try {
      yield call(saga);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      yield fork(runSaga);
    }
  };
  return runSaga;
}

function* runSagas() {
  yield call(appUpstartSaga);
  yield fork(createRetryableSaga(appRootSaga));

  // this ensures that query parameters are set in the reducer
  // before any upstart saga runs
  yield take(actionTypes.SET_LOCATION);

  const forkedUpstartSagas = upstartSagas.map(saga => fork(saga));
  yield all(forkedUpstartSagas);

  const retryableRootSagas = rootSagas.map(createRetryableSaga).map(saga => fork(saga));
  yield all(retryableRootSagas);
}

const SagaManager = {
  startSagas(sagaMiddleware) {
    sagaMiddleware.run(runSagas);
  },
};

export default SagaManager;
