import moment from 'moment';
import {
  Record,
} from 'immutable';

const defaults = {
  id: null,
  eventId: null,
  createdAt: moment(),
  updatedAt: moment(),
  body: '',
};

class Note extends Record(defaults) {
  get start() {
    return moment(this.createdAt);
  }
  get end() {
    return moment(this.createdAt);
  }
}

export default Note;
