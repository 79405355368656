import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, MenuItem,
} from '@material-ui/core';
import moment from 'moment';

import Note from 'Modules/Note/data/Note';
import NoteIcon from 'Modules/Events/components/NoteIcon';

const styles = {
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 24,
    '&:hover': {
      backgroundColor: '#EEF6F7',
    },
  },
  noteIcon: {
    paddingRight: 12,
  },
  noteDuration: {
    width: 112,
    marginRight: 16,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    fontStyle: 'italic',
  },
  draftText: {
    color: '#EE6352',
  },
  draftTypography: {
    width: 240,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
};

@withStyles(styles)
export default class NoteDraft extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    handleClick: PropTypes.func.isRequired,
    note: ImmutablePropTypes.recordOf(Note).isRequired,
  };

  render() {
    const {
      classes, note,
    } = this.props;
    return (
      <MenuItem
        key={note.id}
        className={classes.root}
        onClick={this.props.handleClick}
      >
        <Typography
          className={classes.noteDuration}
        >
          {moment(note.start).format('h:mma')}
        </Typography>
        <NoteIcon classes={{ root: classes.noteIcon }} />
        <Typography
          variant="body2"
          className={classes.draftTypography}
        >
          <span className={classes.draftText}>Draft: </span>
          {note.body}
        </Typography>
      </MenuItem>
    );
  }
}

