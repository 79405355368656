import React, {
  Component,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Note from 'Modules/Note/containers/ModuleRoot';
import Events from 'Modules/Events/containers/ModuleRoot';
import { getIsInDetailView } from 'Modules/App/selectors';
import Header from 'Modules/App/containers/Header';
import { getNotesEnabled } from 'Modules/Note/selectors';

import ZeroState from './ZeroState';

const styles = theme => ({
  '@global body': {
    margin: 0,
    padding: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    height: '100vh',
    overflow: 'hidden',
  },
  root: {
    height: '100vh',
  },
  content: {
    display: 'flex',
    position: 'relative',
    transition: 'left 0.3s',
    left: 0,
  },
  zeroState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  zeroStateDescription: {
    fontSize: 24,
    fontWeight: 'normal',
    marginTop: 32,
  },
  zeroStateActionText: {
    marginTop: 16,
  },
  learnMoreLink: {
    color: theme.palette.primary[500],
    textDecoration: 'none',
    marginTop: 8,
  },
});

@connect(
  state => ({
    isInDetailView: getIsInDetailView(state),
    notesEnabled: getNotesEnabled(state),
  }),
  {
  },
)
@withStyles(styles)
export default class ModuleRoot extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isInDetailView: PropTypes.bool.isRequired,
    notesEnabled: PropTypes.bool.isRequired,
  }

  constructor() {
    super();

    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.resizeListener = () => this.setState({ windowWidth: window.innerWidth });
    window.addEventListener('resize', this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  render() {
    const {
      classes,
      isInDetailView,
      notesEnabled,
    } = this.props;

    const { windowWidth } = this.state;
    return (
      <div className={classes.root}>
        <Header />
        <div
          className={classes.content}
          style={{
            width: windowWidth * 2,
            left: isInDetailView ? (-1 * windowWidth) : 0,
          }}
        >
          {notesEnabled && (
            <Fragment>
              <Events />
              <Note />
            </Fragment>
          )}
          {!notesEnabled && (
            <div className={classes.zeroState}>
              <ZeroState />
              <Typography
                className={classes.zeroStateDescription}
                variant="title"
              >
                Looks like this isn’t set up in Salesforce yet
              </Typography>
              <Typography
                className={classes.zeroStateActionText}
              >
                Please contact your admin to setup meeting notes.
              </Typography>
              <Typography>
                <a
                  className={classes.learnMoreLink}
                  href="https://help.groove.co/articles/2638714-easily-take-notes-for-your-calendar-events#enabling-this-feature-for-your-team-for-salesforce-admins"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
}
