import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  List as MuiList, ListItem,
} from '@material-ui/core';
import moment from 'moment';
import { List } from 'immutable';

import NowLine from 'Modules/Events/containers/NowLine';
import EventOrNoteDraft from 'Modules/Events/components/EventOrNoteDraft';

import DayGroupDate from '../DayGroupDate';


const styles = {
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 24,
  },
  eventsAndNoteDraftsList: {
    flexGrow: 1,
  },
};

class EventsAndNoteDraftsDayGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      now: moment(),
    };
  }

  componentDidMount() {
    this.periodicNoteUpdater = setInterval(() => this.setState({ now: moment() }), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.periodicNoteUpdater);
  }

  // this logic determines whether the now line should be drawn
  // between two events/notes.
  shouldRenderNowLine = ({ eventOrNoteDraft }) => {
    const { day } = this.props;
    const { now } = this.state;
    return (
      day === now.format('YYYY-MM-DD') &&
      eventOrNoteDraft.start.isAfter(now)
    );
  }

  // this handles the edge case for if the now line comes after ALL the events in a day
  shouldRenderNowLineAtEnd = ({
    eventsAndNoteDrafts,
  }) => {
    const { day } = this.props;
    const { now } = this.state;
    return (
      day === now.format('YYYY-MM-DD') &&
      (
        (eventsAndNoteDrafts.size > 0 && eventsAndNoteDrafts.last().start.isBefore(now)) ||
        eventsAndNoteDrafts.isEmpty()
      )
    );
  };

  createEventOrNoteDraftsWithNowLine() {
    const {
      eventsAndNoteDrafts,
    } = this.props;
    let hasAlreadyRenderedNowLine = false;
    let elements = eventsAndNoteDrafts.reduce((acc, eventOrNoteDraft) => {
      let tempList = acc;
      if (!hasAlreadyRenderedNowLine && this.shouldRenderNowLine({ eventOrNoteDraft })) {
        hasAlreadyRenderedNowLine = true;
        tempList = tempList.push(<NowLine key={'nowLine'} />);
      }
      return tempList.push(<EventOrNoteDraft
        key={eventOrNoteDraft.id}
        eventOrNoteDraft={eventOrNoteDraft}
      />,
      );
    }, new List());
    if (!hasAlreadyRenderedNowLine && this.shouldRenderNowLineAtEnd({
      eventsAndNoteDrafts,
    })) {
      elements = elements.push(<NowLine key={'nowLineEnd'} />);
    }
    return elements;
  }

  render() {
    const {
      classes,
      day,
    } = this.props;
    return (
      <ListItem
        key={day}
        className={classes.root}
      >
        <DayGroupDate day={day} />
        <MuiList className={classes.eventsAndNoteDraftsList}>
          {this.createEventOrNoteDraftsWithNowLine()}
        </MuiList>
      </ListItem>
    );
  }
}

EventsAndNoteDraftsDayGroup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  day: PropTypes.string.isRequired,
  eventsAndNoteDrafts: PropTypes.instanceOf(List).isRequired,
};

export default withStyles(styles)(EventsAndNoteDraftsDayGroup);
