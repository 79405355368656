import moment from 'moment';
import { createSelector } from 'reselect';

import { getNotes } from 'Modules/Note/selectors';

export const getEvents = state => state.getIn(['events', 'events']);
export const getEventList = state => state.getIn(['events', 'eventList']);
export const makeGetEvent = eventId => createSelector(
  getEvents,
  events => events.get(eventId),
);

export const getEventByUid = (state, { uid }) => getEventList(state).find(event => event.iCalUID === uid);

export const getIsFetchingEvents = state => state.getIn(['events', 'isFetchingEvents']);
export const getEventsAndNoteDraftsGroupedByDay = createSelector(
  getEventList,
  getNotes,
  (events, notes) => {
    const eventsAndNotes = events
      .concat(notes.filter(note => !note.eventId))
      .sortBy(eventOrNote => eventOrNote.start);

    return eventsAndNotes.groupBy(eventOrNote => moment(eventOrNote.start).format('YYYY-MM-DD'));
  },
);
