import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Input, CircularProgress,
} from '@material-ui/core';

import { saveNote } from 'Modules/Note/actions';
import {
  getNoteBody, getIsFetchingNote,
} from 'Modules/Note/selectors';

const styles = {
  inputRoot: {
    backgroundColor: '#F5F5F5',
  },
  inputElement: {
    padding: 8,
    fontSize: '12px !important',
  },
  loader: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

@connect(
  state => ({
    noteBody: getNoteBody(state),
    isFetchingNote: getIsFetchingNote(state),
  }),
  {
    saveNote,
  },
)
@withStyles(styles)
export default class NoteInput extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isFetchingNote: PropTypes.bool.isRequired,
    saveNote: PropTypes.func.isRequired,
    noteBody: PropTypes.string,
  };

  static defaultProps = {
    noteBody: '',
  }

  focusInput = (ref) => {
    if (ref) {
      ref.focus({ preventScroll: true });
    }
  }

  handleKeyDown = (e) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const inputUntilCarat = input.value.substring(0, start);
    const inputAfterCarat = input.value.substring(end);

    let lastLineBreak = inputUntilCarat.lastIndexOf('\n');

    // edge case for beginning of string
    if (lastLineBreak === -1) {
      lastLineBreak = 0;
    }
    const lastLine = inputUntilCarat.slice(lastLineBreak);

    if (e.key === 'Tab') {
      e.preventDefault();

      if (e.shiftKey) {
        if (inputUntilCarat[inputUntilCarat.length - 1] === '\t') {
          input.value = `${inputUntilCarat.slice(0, inputUntilCarat.length - 1)}${inputAfterCarat}`;
        }
      } else {
        // set textarea value to: text before caret + tab + text after caret
        input.value = `${inputUntilCarat}\t${inputAfterCarat}`;

        // put caret at right position again
        input.selectionStart = start + 1;
        input.selectionEnd = start + 1;
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const numberOfTabCharacters = [...lastLine].filter(char => char === '\t').length;
      let extraCharacters = '';

      const firstNonWhitespaceCharacterAfterLineBreak = lastLine.trim()[0];

      if (['-', '*'].includes(firstNonWhitespaceCharacterAfterLineBreak)) {
        extraCharacters += `${firstNonWhitespaceCharacterAfterLineBreak} `;
      }


      const tabs = new Array(numberOfTabCharacters).fill('\t').join('');
      // set textarea value to: text before caret + tab + text after caret
      input.value = `${inputUntilCarat}\n${tabs}${extraCharacters}${inputAfterCarat}`;
      // put caret at right position again
      input.selectionStart = start + 1 + numberOfTabCharacters + extraCharacters.length;
      input.selectionEnd = start + 1 + numberOfTabCharacters + extraCharacters.length;
    }
  }

  handleOnChange = e => this.props.saveNote({ body: e.target.value });

  renderLoader = () => (
    <div className={this.props.classes.loader}>
      <CircularProgress />
    </div>
  )

  renderInput = () => {
    const {
      classes, noteBody,
    } = this.props;

    return (
      <Input
        fullWidth
        inputRef={this.focusInput}
        multiline
        disableUnderline
        defaultValue={noteBody}
        rows={20}
        placeholder="Type Notes here…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputElement,
        }}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleOnChange}
      />
    );
  }

  render() {
    const { isFetchingNote } = this.props;

    return isFetchingNote ? this.renderLoader() : this.renderInput();
  }
}
