import { RIT } from '@groove-labs/groove-ui';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MAIN_TOOLBAR_HEIGHT, GROOVEY_BLUEY } from 'Modules/App/constants';
import {
  getLastSavedAt,
  getIsDraft,
  getIsDeletingNote,
  getIsSavingNote,
  getSaveError,
} from 'Modules/Note/selectors';
import Check from 'Modules/Note/components/Check';
import NoteInput from 'Modules/Note/containers/NoteInput';
import MeetingSelector from 'Modules/Note/containers/MeetingSelector';
import { deleteNote } from 'Modules/Note/actions';

const styles = {
  root: {
    position: 'relative',
    top: MAIN_TOOLBAR_HEIGHT,
    height: `calc(100vh - ${MAIN_TOOLBAR_HEIGHT}px)`,
    flexGrow: 1,
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  buttonLabelTypography: {
    color: '#5C5C5C',
  },
  captionTypography: {
    fontStyle: 'italic',
    paddingTop: 8,
    paddingBottom: 16,
  },
  inputRoot: {
    backgroundColor: '#F5F5F5',
  },
  inputElement: {
    padding: 8,
    fontSize: '12px !important',
  },
  lastSavedAtTypography: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
  checkIcon: {
    paddingRight: 8,
  },
  bottomRow: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 8,
  },
  deleteDraftButton: {
    alignSelf: 'center',
    marginLeft: 'auto',
    color: GROOVEY_BLUEY,
  },
};

@connect(
  state => ({
    lastSavedAt: getLastSavedAt(state),
    isDraft: getIsDraft(state),
    isDeletingNote: getIsDeletingNote(state),
    isSavingNote: getIsSavingNote(state),
    saveError: getSaveError(state),
  }),
  {
    deleteNote,
  },
)
@withStyles(styles)
export default class ModuleRoot extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    deleteNote: PropTypes.func.isRequired,
    isDeletingNote: PropTypes.bool.isRequired,
    isDraft: PropTypes.bool.isRequired,
    isSavingNote: PropTypes.bool.isRequired,
    lastSavedAt: PropTypes.instanceOf(moment),
    saveError: PropTypes.string,
  };

  static defaultProps = {
    lastSavedAt: null,
    saveError: null,
  }

  renderIsSavingText = () => 'Logging in Salesforce'
  renderLastSavedAt = () => `Logged in Salesforce ${this.props.lastSavedAt.format('h:mma')}`

  renderStatusText = () => {
    const {
      classes,
      lastSavedAt,
      isSavingNote,
      saveError,
    } = this.props;

    return (
      <Typography
        variant="caption"
        className={classes.lastSavedAtTypography}
      >

        {RIT(saveError, <Typography
          variant="caption"
          color="error"
        >An error occurred while saving this note.</Typography>)}
        {RIT(!saveError && !isSavingNote && lastSavedAt, <Check classes={{ root: classes.checkIcon }} />)}
        {RIT(!saveError && isSavingNote, this.renderIsSavingText)}
        {RIT(!saveError && !isSavingNote && lastSavedAt, this.renderLastSavedAt)}
      </Typography>
    );
  }

  render() {
    const {
      classes,
      deleteNote,
      isDraft,
      isDeletingNote,
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography
          className={classes.buttonLabelTypography}
          variant="button"
        >
          Notes Logged To:
        </Typography>

        <MeetingSelector />

        <Typography
          variant="caption"
          className={classes.captionTypography}
        >
          *Notes are saved to the Note field on the meeting in Salesforce
        </Typography>

        <NoteInput />
        <div className={classes.bottomRow}>
          {this.renderStatusText()}
          {RIT(isDraft, (
            <Button
              color="primary"
              disabled={isDeletingNote}
              className={classes.deleteDraftButton}
              onClick={deleteNote}
            >
            Delete Draft
            </Button>
          ))}

        </div>
      </div>
    );
  }
}
