import { actions } from '@groove-labs/groove-ui';

import {
  put, call,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/Note/actions';
import VisualforceClient from 'Utils/VisualforceClient';
import { VisualforceError } from 'Modules/Note/errors';
import { pushQueryParameters } from 'Utils/history';
import { MEETING_DROPDOWN_UI_KEY_PATH } from 'Modules/Note/constants';

const { setProperty } = actions.ui;

export default function* handleAssociateNoteToEvent({ payload }) {
  const {
    noteId,
    eventId,
  } = payload;
  yield put({ type: actionTypes.ASSOCIATE_NOTE_TO_EVENT.PROGRESS });

  try {
    yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
      mode: 'post',
      link: true,
      note_id: noteId,
      cal_uid: eventId,
    });

    yield call(pushQueryParameters, payload);
    yield put({ type: actionTypes.ASSOCIATE_NOTE_TO_EVENT.SUCCESS });
  } catch (e) {
    if (e.name === VisualforceError.name) {
      yield put({
        type: actionTypes.ASSOCIATE_NOTE_TO_EVENT.FAILURE,
        payload: e.errors,
      });
    } else {
      throw e;
    }
  } finally {
    yield put(setProperty({
      uiKeyPath: MEETING_DROPDOWN_UI_KEY_PATH,
      data: false,
    }));
  }
}
