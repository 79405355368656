import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { GROOVEY_BLUEY } from 'Modules/App/constants';

const styles = {
  root: {
  },
};

const Check = ({ classes }) => (
  <svg
    className={classes.root}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Meeting-Notes"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Meeting-notes---click-from-preview"
        transform="translate(-415 -670)"
      >
        <g
          id="Group-4"
          transform="translate(387 149)"
        >
          <g
            id="ic_check_teal"
            transform="translate(25 516)"
          >
            <polygon
              id="Bounds"
              fillOpacity="0"
              fill="#F00"
              points="0 0 24 0 24 24 0 24"
            />
            <polygon
              id="Shape"
              fill={GROOVEY_BLUEY}
              points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
Check.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Check);
