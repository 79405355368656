import {
  withStyles,
  ClickAwayListener,
} from '@material-ui/core';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  actions,
  selectors,
} from '@groove-labs/groove-ui';

import { getEventId } from 'Modules/App/selectors';
import {
  makeGetEvent,
  getIsFetchingEvents,
  getEventsAndNoteDraftsGroupedByDay,
} from 'Modules/Events/selectors';
import Event from 'Modules/Events/data/Event';
import EventCard from 'Modules/Note/components/EventCard';
import ChooseAMeetingButton from 'Modules/Note/components/ChooseAMeetingButton';
import {
  MEETING_SELECTOR_BUTTON_HEIGHT,
  MEETING_DROPDOWN_UI_KEY_PATH,
} from 'Modules/Note/constants';

import MeetingSelectorDropdown from '../MeetingSelectorDropdown';

const { setProperty } = actions.ui;
const { getProperty } = selectors.ui;

const styles = {
  root: {
    height: MEETING_SELECTOR_BUTTON_HEIGHT,
    border: '1px solid rgba(0,0,0,0.19)',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 10,
  },
  menuContainer: {
    border: '1px solid rgba(0,0,0,0.19)',
    backgroundColor: 'white',
    overflowY: 'scroll',
    maxHeight: 300,
  },
  dropdownContainer: {
    maxHeight: 0,
    width: '100%',
    position: 'relative',
  },
  dropdownSubcontainer: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
  },
};

@connect(
  (state) => {
    const eventId = getEventId(state);
    return {
      isFetchingEvents: getIsFetchingEvents(state),
      event: makeGetEvent(eventId)(state),
      eventsAndNoteDraftsGroupedByDay: getEventsAndNoteDraftsGroupedByDay(state),
      dropdownIsOpen: getProperty(state, MEETING_DROPDOWN_UI_KEY_PATH),
    };
  },
  {
    setProperty,
  },
)
@withStyles(styles)
export default class MeetingSelector extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    eventsAndNoteDraftsGroupedByDay: ImmutablePropTypes.orderedMapOf(
      ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf(Event)).isRequired,
      PropTypes.string.isRequired,
    ).isRequired,
    isFetchingEvents: PropTypes.bool.isRequired,
    setProperty: PropTypes.func.isRequired,
    dropdownIsOpen: PropTypes.bool,
    event: ImmutablePropTypes.recordOf(Event),
  };

  static defaultProps = {
    event: null,
    dropdownIsOpen: false,
  }

  componentDidMount() {
    this.closeDropdown();
  }

  componentWillUnmount() {
    this.closeDropdown();
  }

  closeDropdown = () => this.props.setProperty({
    uiKeyPath: MEETING_DROPDOWN_UI_KEY_PATH,
    data: false,
  })

  handleMenuToggle = () => this.props.setProperty({
    uiKeyPath: MEETING_DROPDOWN_UI_KEY_PATH,
    data: !this.props.dropdownIsOpen,
  })

  render() {
    const {
      event,
      isFetchingEvents,
      classes,
      eventsAndNoteDraftsGroupedByDay,
      dropdownIsOpen,
    } = this.props;
    const eventCardProps = {
      event,
      isFetchingEvents,
    };
    const meetingButtonProps = {
      isFetchingEvents,
      onClick: this.handleMenuToggle,
    };
    const dropdownProps = {
      eventsAndNoteDraftsGroupedByDay,
    };

    return (
      <ClickAwayListener onClickAway={this.closeDropdown}>
        <div className={classes.root}>
          {event ? <EventCard {...eventCardProps} /> : <ChooseAMeetingButton {...meetingButtonProps} />}
          <div className={classes.dropdownContainer}>
            <div className={classes.dropdownSubcontainer}>
              {dropdownIsOpen ? <MeetingSelectorDropdown {...dropdownProps} /> : null}
            </div>
          </div>
        </div >
      </ClickAwayListener>
    );
  }
}
