import {
  put,
  call,
  select,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/Note/actions';
import VisualforceClient from 'Utils/VisualforceClient';
import { VisualforceError } from 'Modules/Note/errors';
import { getEventByUid } from 'Modules/Events/selectors';
import { SALESFORCE_EVENT_DATE_FORMAT } from 'Modules/Note/constants';

export default function* handleLogEventToSalesforce({ payload }) {
  const {
    eventId,
  } = payload;

  yield put({ type: actionTypes.LOG_EVENT_TO_SALESFORCE.PROGRESS });

  const event = yield select(getEventByUid, { uid: eventId });

  try {
    yield call([VisualforceClient, 'call'], 'DaScoopComposer__GrooveEvents', {
      uid: eventId,
      StartDateTime: event.start.format(SALESFORCE_EVENT_DATE_FORMAT),
      EndDateTime: event.end.format(SALESFORCE_EVENT_DATE_FORMAT),
      IsAllDayEvent: event.isAllDay,
      Subject: event.title,
      TrackEvent: true,
      alwaysCreate: true,
      alternateMethod: 'post',
    });

    yield put({ type: actionTypes.LOG_EVENT_TO_SALESFORCE.SUCCESS });
  } catch (e) {
    if (e.name === VisualforceError.name) {
      yield put({
        type: actionTypes.LOG_EVENT_TO_SALESFORCE.FAILURE,
        payload: e.errors,
      });
    } else {
      throw e;
    }
  }
}
