import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const styles = {
  day: {
    alignSelf: 'baseline',
    marginRight: 16,
  },
  dayTypography: {
    fontWeight: 'normal',
    color: 'rgba(0,0,0,0.87)',
  },
};

const DayGroupDate = ({
  classes, day,
}) => (
  <div className={classes.day}>
    <Typography
      variant="caption"
      className={classes.dayTypography}
    >
      {moment(day).format('ddd')}
    </Typography>
    <Typography variant="body1">
      {moment(day).format('MMM DD')}
    </Typography>
  </div>
);

DayGroupDate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  day: PropTypes.string.isRequired,
};

export default withStyles(styles)(DayGroupDate);
