import { createSelector } from 'reselect';

export const getQueryParameters = state => state.getIn(['app', 'location', 'queryParameters']);

export const getCurrentUserData = state => state.getIn(['app', 'currentUser']);

export const getIsInDetailView = createSelector(
  getQueryParameters,
  queryParameters => queryParameters.has('eventId') || queryParameters.has('noteId'),
);

export const getIsEditingNote = createSelector(
  getQueryParameters,
  queryParameters => queryParameters.has('noteId') && queryParameters.get('noteId') !== 'new',
);


export const getEventId = createSelector(
  getQueryParameters,
  queryParameters => queryParameters.get('eventId'),
);

export const getNoteId = createSelector(
  getQueryParameters,
  queryParameters => queryParameters.get('noteId'),
);
