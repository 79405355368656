import { listToOrderedMap } from '@groove-labs/groove-ui';
import {
  fromJS, OrderedMap, List,
} from 'immutable';
import moment from 'moment';

import { actionTypes } from 'Modules/Events/actions';


const initialState = fromJS({
  isFetchingEvents: true,
  eventList: new List(),
  events: new OrderedMap(),
  eventsGroupedByDay: new OrderedMap(),
});

export default function events(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS.BEGIN:
    case actionTypes.FETCH_EVENTS.PROGRESS: {
      return state.set('isFetchingEvents', true);
    }

    case actionTypes.FETCH_EVENTS.FAILURE: {
      return state.set('isFetchingEvents', false);
    }
    case actionTypes.FETCH_EVENTS.SUCCESS: {
      const events = fromJS(action.payload);
      return state
        .set('eventList', events)
        .set('events', listToOrderedMap(events, event => event.iCalUID))
        .set('isFetchingEvents', false)
        .set('eventsGroupedByDay', events.groupBy(
          event => moment(event.start).format('YYYY-MM-DD'),
        ));
    }
    default:
      return state;
  }
}
