import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  APP_BAR_HEIGHT, SCROLLABLE_VIEW,
} from 'Modules/App/constants';

const styles = {
  root: {
    background: '#DB4437',
    height: 2,
    position: 'absolute',
    width: 'calc(100% + 24px)',
    zIndex: 999,
    pointerEvents: 'none',
  },
};

@withStyles(styles)
export default class NowLine extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  scrollList = (nowLine) => {
    this.nowLine = nowLine;
    if (nowLine) {
      const listEl = document.getElementById(SCROLLABLE_VIEW);
      const extraSpace = 32; // TODO @vijaypemmaraju: figure out where this is coming from
      const twoThirdHeight = (listEl.clientHeight + APP_BAR_HEIGHT + extraSpace) * (2.0 / 3);
      const nowLinePosition = this.nowLine.getBoundingClientRect().top;
      listEl.scrollBy(0, nowLinePosition - twoThirdHeight);
    }
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div
        ref={this.scrollList}
        className={classes.root}
      />
    );
  }
}
