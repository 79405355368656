
import isEmpty from 'lodash-es/isEmpty';

import {
  actionTypes,
} from 'Modules/Note/actions';
import {
  basicEventTrackingHandler,
} from 'Modules/App/sagas/analytics';
import {
  all,
  call,
  fork,
  takeEvery,
} from 'redux-saga/effects';


function* handleTrackMeetingNoteLogged({ payload }) {
  const {
    eventId,
  } = payload;
  const hasEventId = !isEmpty(eventId);

  yield call(basicEventTrackingHandler, {
    featureCategory: 'notes',
    eventName: 'Meeting Note Logged',
    properties: {
      hasEventId,
    },
  });
}

function* watchNoteLoggedSuccessfully() {
  yield takeEvery(actionTypes.SAVE_NOTE.SUCCESS, handleTrackMeetingNoteLogged);
}

export default function* root() {
  yield all([
    fork(watchNoteLoggedSuccessfully),
  ]);
}
