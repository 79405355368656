import {
  createActions, keyMirror, standardActions,
} from 'action-utils';

export const actionTypes = createActions({
  NOTE: {
    ...keyMirror([
      'SET_NOTES_ENABLED',
    ]),
    FETCH_ALL_NOTES: standardActions(),
    FETCH_NOTE: standardActions(),
    SAVE_NOTE: standardActions(),
    DELETE_NOTE: standardActions(),
    ASSOCIATE_NOTE_TO_EVENT: standardActions(),
    LOG_EVENT_TO_SALESFORCE: standardActions(),
  },
}).NOTE;

export const setNotesEnabled = () => ({
  type: actionTypes.SET_NOTES_ENABLED,
});

export const fetchNote = () => ({
  type: actionTypes.FETCH_NOTE.BEGIN,
});

export const fetchAllNotes = () => ({
  type: actionTypes.FETCH_ALL_NOTES.BEGIN,
});

export const saveNote = ({
  body,
  selectedEventId,
}) => ({
  type: actionTypes.SAVE_NOTE.BEGIN,
  payload: {
    body,
    selectedEventId,
  },
});

export const deleteNote = () => ({
  type: actionTypes.DELETE_NOTE.BEGIN,
});

export const associateNoteToEvent = ({
  noteId,
  eventId,
}) => ({
  type: actionTypes.ASSOCIATE_NOTE_TO_EVENT.BEGIN,
  payload: {
    noteId,
    eventId,
  },
});

export const logEventToSalesforce = ({
  eventId,
}) => ({
  type: actionTypes.LOG_EVENT_TO_SALESFORCE.BEGIN,
  payload: {
    eventId,
  },
});
