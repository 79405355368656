import moment from 'moment';
import {
  fromJS, OrderedMap,
} from 'immutable';

import { actionTypes } from 'Modules/Note/actions';
import Note from 'Modules/Note/data/Note';

const initialState = fromJS({
  notes: new OrderedMap(),
  body: '',
  saveError: null,
  isDeletingNote: false,
  isFetchingNote: true,
  isSavingNote: false,
  lastSavedAt: null,
  notesEnabled: true,
});

export default function note(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_ALL_NOTES.SUCCESS: {
      return state.set('notes', action.payload);
    }
    case actionTypes.SET_NOTES_ENABLED: {
      return state.set('notesEnabled', false);
    }
    case actionTypes.FETCH_NOTE.PROGRESS: {
      return state
        .set('lastSavedAt', null)
        .set('isFetchingNote', true)
        .set('body', action.payload);
    }
    case actionTypes.FETCH_NOTE.FAILURE: {
      return state
        .set('isFetchingNote', false);
    }
    case actionTypes.FETCH_NOTE.SUCCESS: {
      return state
        .set('isFetchingNote', false)
        .set('body', action.payload);
    }

    case actionTypes.SAVE_NOTE.PROGRESS: {
      return state
        .set('saveError', null)
        .set('isSavingNote', true);
    }

    case actionTypes.SAVE_NOTE.FAILURE: {
      return state
        .set('saveError', action.payload)
        .set('isSavingNote', false);
    }

    case actionTypes.SAVE_NOTE.SUCCESS: {
      return state
        .set('isSavingNote', false)
        .set('lastSavedAt', moment())
        .setIn(['notes', action.payload.id], new Note(action.payload));
    }

    case actionTypes.DELETE_NOTE.BEGIN: {
      return state
        .set('isDeletingNote', true);
    }

    case actionTypes.DELETE_NOTE.FAILURE: {
      return state
        .set('isDeletingNote', false);
    }

    case actionTypes.DELETE_NOTE.SUCCESS: {
      const noteId = action.payload;
      return state
        .set('isDeletingNote', false)
        .deleteIn(['notes', noteId]);
    }
    case '':
    default:
      return state;
  }
}
