import { connect } from 'react-redux';
import { RIT } from '@groove-labs/groove-ui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Event from 'Modules/Events/containers/Event';
import EventRecord from 'Modules/Events/data/Event';
import NoteDraft from 'Modules/Events/containers/NoteDraft';
import Note from 'Modules/Note/data/Note';
import { selectNote } from 'Modules/Events/actions';

@connect(
  () => ({}),
  {
    selectNote,
  },
)
class EventOrNoteDraft extends React.Component {
  handleSelectNoteDraft = () => {
    const {
      eventOrNoteDraft,
      selectNote,
    } = this.props;

    selectNote({
      noteId: eventOrNoteDraft.id,
    });
  }

  handleSelectEvent = (note) => {
    const {
      eventOrNoteDraft,
      selectNote,
    } = this.props;

    selectNote({
      noteId: note ? note.id : 'new',
      eventId: eventOrNoteDraft.iCalUID,
    });
  }

  componentProps() {
    const { eventOrNoteDraft } = this.props;
    const eventProps = {
      handleClick: this.handleSelectEvent,
      event: eventOrNoteDraft,
    };
    const noteDraftProps = {
      handleClick: this.handleSelectNoteDraft,
      note: eventOrNoteDraft,
    };
    return {
      eventProps,
      noteDraftProps,
    };
  }

  render() {
    const { eventOrNoteDraft } = this.props;
    const {
      eventProps,
      noteDraftProps,
    } = this.componentProps();
    return (
      <Fragment>
        {RIT((eventOrNoteDraft instanceof EventRecord), (
          <Event {...eventProps} />
        ))}
        {RIT((eventOrNoteDraft instanceof Note), (
          <NoteDraft {...noteDraftProps} />
        ))}
      </Fragment>
    );
  }
}

EventOrNoteDraft.propTypes = {
  eventOrNoteDraft: PropTypes.oneOfType([
    ImmutablePropTypes.recordOf(EventRecord),
    ImmutablePropTypes.recordOf(Note),
  ]).isRequired,
};

export default EventOrNoteDraft;
