import { RIT } from '@groove-labs/groove-ui';
import {
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import EventRecord from 'Modules/Events/data/Event';

const padding = 16;
const styles = {
  root: {
    width: `calc(100% - ${2 * padding}px)`,
    display: 'flex',
    paddingLeft: padding,
    paddingRight: padding,
    flexGrow: 1,
  },
  eventIcon: {
    opacity: 0.87,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTypographies: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

const EventCard = ({
  classes,
  event,
  isFetchingEvents,
}) => (
  <div className={classes.root}>
    {RIT(!isFetchingEvents, (
      <Fragment>
        <div className={classes.iconContainer}>
          <EventIcon className={classes.eventIcon} />
        </div>
        <div className={classes.cardTypographies}>
          <Typography variant="body2">
            {event.title}
          </Typography>
          <Typography>
            {event.start.format('LLLL')}
          </Typography>
        </div>
      </Fragment>
    ))}
  </div>
);

EventCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isFetchingEvents: PropTypes.bool.isRequired,
  event: ImmutablePropTypes.recordOf(EventRecord),
};

EventCard.defaultProps = {
  event: null,
};

export default withStyles(styles)(EventCard);
