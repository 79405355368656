import { combineReducers } from 'redux-immutable';
import { reducers as grooveUIReducers } from '@groove-labs/groove-ui';

import { reducer as app } from 'Modules/App';
import { reducer as events } from 'Modules/Events';
import { reducer as note } from 'Modules/Note';

export default combineReducers({
  app,
  events,
  note,
  ...grooveUIReducers,
});
