import { fromJS } from 'immutable';

import { actionTypes } from 'Modules/App/actions';

const initialState = fromJS({
  location: {
    queryParameters: {},
  },
  currentUser: {
    id: null,
    email: '',
  },
});

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_LOCATION: {
      return state.set('location', fromJS(action.payload));
    }

    case actionTypes.SET_CURRENT_USER_INFO: {
      return state.set('currentUser', fromJS(action.payload));
    }

    default:
      return state;
  }
}
