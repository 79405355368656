import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/Note/actions';
import { getQueryParameters } from 'Modules/App/selectors';
import { goBackToEvents } from 'Modules/Events/actions';
import VisualforceClient from 'Utils/VisualforceClient';
import { VisualforceError } from 'Modules/Note/errors';

export default function* handleDeleteNote() {
  yield put({ type: actionTypes.DELETE_NOTE.PROGRESS });
  const queryParameters = yield select(getQueryParameters);
  const noteId = queryParameters.get('noteId');
  try {
    yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
      mode: 'delete',
      note_id: noteId,
    });
    yield put({
      type: actionTypes.DELETE_NOTE.SUCCESS,
      payload: noteId,
    });

    yield put(goBackToEvents());
  } catch (e) {
    if (e.name === VisualforceError.name) {
      yield put({
        type: actionTypes.DELETE_NOTE.FAILURE,
        payload: e.errors,
      });
    } else {
      throw e;
    }
  }
}
