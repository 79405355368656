import {
  fork,
  takeEvery,
  call,
  all,
  put,
  select,
} from 'redux-saga/effects';
import fetchEvents from 'Modules/Events/sagas/fetchEvents';
import { actionTypes } from 'Modules/Events/actions';
import { pushQueryParameters } from 'Utils/history';
import {
  fetchNote,
  fetchAllNotes,
} from 'Modules/Note/actions';
import { getQueryParameters } from 'Modules/App/selectors';

function* handleSelectNote({ payload: {
  noteId, eventId,
} }) {
  const params = {
    noteId,
    eventId,
  };
  yield call(pushQueryParameters, params);
  yield put(fetchNote());
}

function* handleGoBackToEvents() {
  yield all([
    put(fetchAllNotes()),
    call(pushQueryParameters, {}),
  ]);
}

function* watchFetchEvents() {
  yield takeEvery(actionTypes.FETCH_EVENTS.BEGIN, fetchEvents);
}

function* watchSelectNote() {
  yield takeEvery(actionTypes.SELECT_NOTE, handleSelectNote);
}

function* watchGoBackToEvents() {
  yield takeEvery(actionTypes.GO_BACK_TO_EVENTS, handleGoBackToEvents);
}

export function* upstart() {
  const params = yield select(getQueryParameters);
  const instanceEventId = params.get('instanceEventId') || null;
  yield* fetchEvents({ instanceEventId });
}

export default function* root() {
  yield all([
    fork(watchFetchEvents),
    fork(watchSelectNote),
    fork(watchGoBackToEvents),
  ]);
}
