import qs from 'query-string';
import createHistory from 'history/createBrowserHistory';

const history = createHistory();

export const pushQueryParameters = (queryParameters) => {
  history.push(`?${qs.stringify(queryParameters)}`);
};

export default history;
