import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import SagaManager from './sagaManager';

Raven
  .config(process.env.SENTRY_URL, {
    release: process.env.APP_VERSION,
  })
  .install();

// The GrooveHTTPClient will report errors to the global Raven instance
window.Raven = Raven;

function createAppStore() {
  let store;

  const actionsBlacklist = [];

  // Not implementing a state transformer for now
  const ravenMiddleware = createRavenMiddleware(Raven);
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      console.error(error); // eslint-disable-line no-console
    },
  });

  const middleWares = applyMiddleware(
    sagaMiddleware,
    ravenMiddleware,
  );

  if (process.env.NODE_ENV === 'development') {
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
      actionsBlacklist,
    })) || compose;
    store = createStore(rootReducer, undefined, composeEnhancers(middleWares));
  } else {
    store = createStore(rootReducer, undefined, middleWares);
  }

  window.store = store;

  SagaManager.startSagas(sagaMiddleware);

  return store;
}

const store = createAppStore();

export default store;
