import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { goBackToEvents } from 'Modules/Events/actions';
import { getIsInDetailView } from 'Modules/App/selectors';
import {
  getIsSavingNote, getIsDeletingNote,
} from 'Modules/Note/selectors';

@connect(
  state =>
    ({
      isInDetailView: getIsInDetailView(state),
      isSavingNote: getIsSavingNote(state),
      isDeletingNote: getIsDeletingNote(state),
    })
  ,
  {
    goBackToEvents,
  },
)
export default class DetailViewBackButton extends Component {
  static propTypes = {
    goBackToEvents: PropTypes.func.isRequired,
    isDeletingNote: PropTypes.bool.isRequired,
    isInDetailView: PropTypes.bool.isRequired,
    isSavingNote: PropTypes.bool.isRequired,
  };

  defaultStyle = {
    transition: 'opacity 0.3s, width 0.3s',
    opacity: 0,
    width: 0,
    pointerEvents: 'none',
  }

  transitionStyles = {
    entering: { opacity: 0 },
    entered: {
      opacity: 1,
      width: 48,
      pointerEvents: 'auto',
    },
  };

  renderIconButtonWithState = (state) => {
    const {
      goBackToEvents,
      isSavingNote,
      isDeletingNote,
    } = this.props;

    return (
      <IconButton
        color="inherit"
        disabled={isSavingNote || isDeletingNote}
        onClick={goBackToEvents}
        style={{
          ...this.defaultStyle,
          ...this.transitionStyles[state],
        }}
      >
        <ArrowBack />
      </IconButton>
    );
  }

  render() {
    return (
      <Transition
        in={this.props.isInDetailView}
        timeout={0}
      >
        {this.renderIconButtonWithState}
      </Transition>
    );
  }
}
