import {
  RIT,
  actions,
  selectors,
} from '@groove-labs/groove-ui';
import {
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, {
  Fragment,
} from 'react';

import {
  MEETING_SELECTOR_BUTTON_HEIGHT,
  MEETING_DROPDOWN_UI_KEY_PATH,
  CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH,
} from 'Modules/Note/constants';
import { WAVY_NAVY } from 'Modules/App/constants';

const { setProperty } = actions.ui;
const { getProperty } = selectors.ui;

const styles = {
  root: {
    justifyContent: 'space-between',
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    height: MEETING_SELECTOR_BUTTON_HEIGHT,
  },
  chooseAMeetingButtonLabel: {
    flexGrow: 1,
    color: WAVY_NAVY,
  },
};

@connect(
  state => ({
    dropdownIsOpen: getProperty(state, MEETING_DROPDOWN_UI_KEY_PATH),
    buttonEnabled: getProperty(state, CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH),
  }),
  {
    setProperty,
  },
)
@withStyles(styles)
export default class ChooseAMeetingButton extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isFetchingEvents: PropTypes.bool.isRequired,
    setProperty: PropTypes.func.isRequired,
    buttonEnabled: PropTypes.bool,
    dropdownIsOpen: PropTypes.bool,
  };

  static defaultProps = {
    dropdownIsOpen: false,
    buttonEnabled: true,
  }

  componentDidMount() {
    this.props.setProperty({
      uiKeyPath: CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH,
      data: true,
    });
  }

  toggleMenu = () => this.props.setProperty({
    uiKeyPath: MEETING_DROPDOWN_UI_KEY_PATH,
    data: !this.props.dropdownIsOpen,
  })

  render() {
    const {
      classes,
      isFetchingEvents,
      buttonEnabled,
    } = this.props;
    return (
      <Button
        fullWidth
        variant="text"
        className={classes.root}
        classes={{
          label: classes.chooseAMeetingButtonLabel,
        }}
        onClick={this.toggleMenu}
        color="primary"
        disabled={!buttonEnabled}
      >
        {/* this empty span is simply because the button component expects children */}
        {RIT(isFetchingEvents, <span />)}
        {RIT(!isFetchingEvents, (
          <Fragment>
            {buttonEnabled ? 'Choose a Meeting' : 'Associating the Meeting...'}
            <Add />
          </Fragment>
        ))}
      </Button>
    );
  }
}
