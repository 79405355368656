import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Divider,
  List as MuiList,
  Typography,
} from '@material-ui/core';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Set, List, OrderedMap,
} from 'immutable';
import {
  actions, selectors,
} from '@groove-labs/groove-ui';
import moment from 'moment';

import { getQueryParameters } from 'Modules/App/selectors';
import EventRecord from 'Modules/Events/data/Event';
import {
  MEETING_DROPDOWN_UI_KEY_PATH, MEETING_SCROLLABLE_VIEW,
} from 'Modules/Note/constants';
import {
  getNotesWithEventIds,
  getNote,
} from 'Modules/Note/selectors';
import Note from 'Modules/Note/data/Note';

import MeetingDropdownDayGroup from '../MeetingDropdownDayGroup';

const { setProperty } = actions.ui;
const { getProperty } = selectors.ui;

const headerContainer = {
  display: 'flex',
  flexGrow: 1,
};
const styles = {
  menuContainer: {
    border: '1px solid rgba(0,0,0,0.19)',
    backgroundColor: 'white',
    overflowY: 'scroll',
    maxHeight: 300,
    paddingBottom: 0,
  },
  headerContainer: {
    paddingRight: 20,
    paddingLeft: 25,
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(0,0,0, .5)',
  },
  dateTextContainer: headerContainer,
  notesTextContainer: {
    ...headerContainer,
    marginLeft: 50,
  },
};

export const getNoteIdAndPositionForNowLine = (orderedMap, date) => {
  const list = List(orderedMap).map(([, event]) => event).flatten();
  for (let i = 0; i < list.size; i += 1) {
    const {
      start,
      end,
    } = list.get(i);
    const prevDate = list.get(i - 1);
    const nextDate = list.get(i + 1);
    const noteId = list.get(i).id;
    if (date.isAfter(start) && date.isBefore(end)) {
      return {
        noteId,
        position: 'between',
      };
    } else if (prevDate && date.isBefore(start) && date.isAfter(prevDate.end)) {
      return {
        noteId,
        position: 'start',
      };
    } else if (nextDate && date.isAfter(end) && date.isBefore(nextDate.start)) {
      return {
        noteId,
        position: 'end',
      };
    }
  }
  /**
   * @description
   * If it doesn't pass anything from the loop it the draft was either
   * 1. created before every event shown
   * 2. created at the very end
   */
  if (list.size > 0) {
    const firstEventDate = list.get(0);
    if (date.isBefore(firstEventDate)) {
      return {
        noteId: firstEventDate.id,
        position: 'start',
      };
    }
    const lastEventDate = list.get(list.size - 1);
    if (lastEventDate) {
      return {
        noteId: lastEventDate.id,
        position: 'end',
      };
    }
  }
  return {
    noteId: null,
    position: null,
  };
};

@connect(
  (state) => {
    const queryParams = getQueryParameters(state).toJS();
    return {
      noteEventIds: getNotesWithEventIds(state),
      dropdownIsOpen: getProperty(state, MEETING_DROPDOWN_UI_KEY_PATH),
      note: getNote(state, queryParams),
    };
  },
  {
    setProperty,
  },
)
@withStyles(styles)
export default class MeetingSelectorDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    dropdownIsOpen: PropTypes.bool.isRequired,
    eventsAndNoteDraftsGroupedByDay: ImmutablePropTypes.orderedMapOf(
      ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf(EventRecord)).isRequired,
      PropTypes.string.isRequired,
    ).isRequired,
    note: ImmutablePropTypes.recordOf(Note).isRequired,
    noteEventIds: PropTypes.instanceOf(Set).isRequired,
    setProperty: PropTypes.func.isRequired,
  };

  dropdownEvents() {
    const {
      noteEventIds,
      eventsAndNoteDraftsGroupedByDay,
      note,
    } = this.props;
    const validEventGroups = eventsAndNoteDraftsGroupedByDay.reduce((acc, events, date) => {
      const validEvents = events.filter(eventEntry =>
        eventEntry instanceof EventRecord && !noteEventIds.has(eventEntry.iCalUID),
      );
      return validEvents.size > 0 ? acc.set(date, validEvents) : acc;
    }, new OrderedMap());
    let noteIdAndPosition = {
      noteId: null,
      position: null,
    };
    if (note) {
      noteIdAndPosition = getNoteIdAndPositionForNowLine(validEventGroups, moment(note.createdAt));
    }
    return validEventGroups.entrySeq().map(([day, events]) => {
      const props = {
        events,
        day,
        noteIdAndPosition,
        key: day,
        note,
      };
      return <MeetingDropdownDayGroup {...props} />;
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    return (
      <Collapse in={this.props.dropdownIsOpen}>
        <MuiList
          className={classes.menuContainer}
          id={MEETING_SCROLLABLE_VIEW}
        >
          <Typography
            variant="subheading"
            classes={{ root: classes.headerContainer }}
          >
            <div className={classes.dateTextContainer}>
              DATE
            </div>
            <div className={classes.notesTextContainer}>
              NOTES
            </div>
          </Typography>
          <Divider />
          {this.dropdownEvents()}
        </MuiList>
      </Collapse>
    );
  }
}
