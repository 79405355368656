import { RIT } from '@groove-labs/groove-ui';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, MenuItem,
} from '@material-ui/core';
import moment from 'moment';

import EventRecord from 'Modules/Events/data/Event';
import NoteIcon from 'Modules/Events/components/NoteIcon';
import { getNoteByEventId } from 'Modules/Note/selectors';
import Note from 'Modules/Note/data/Note';

const styles = {
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 24,
    flexGrow: 1,
    '&:hover': {
      backgroundColor: '#EEF6F7',
    },
  },
  noteIcon: {
    paddingRight: 12,
  },
  eventDuration: {
    width: 112,
    marginRight: 16,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
  },
};

@connect(
  (state, ownProps) => {
    const note = getNoteByEventId(state, ownProps);
    return { note };
  },
  {},
)
@withStyles(styles)
export default class Event extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    event: ImmutablePropTypes.recordOf(EventRecord).isRequired,
    handleClick: PropTypes.func.isRequired,
    note: ImmutablePropTypes.recordOf(Note),
  };


  static defaultProps = {
    note: null,
  }

  handleSelectEvent = () => this.props.handleClick(this.props.note);

  render() {
    const {
      classes, event, note,
    } = this.props;
    return (
      <MenuItem
        key={event.id}
        className={classes.root}
        onClick={this.handleSelectEvent}
      >
        <Typography
          className={classes.eventDuration}
        >
          {moment(event.start).format('h:mm')} — {moment(event.end).format('h:mma')}
        </Typography>
        {RIT(note, <NoteIcon classes={{ root: classes.noteIcon }} />)}
        <Typography variant="body2">{event.title}</Typography>
      </MenuItem>
    );
  }
}

