import { createSelector } from 'reselect';
import { Set } from 'immutable';

import { getQueryParameters } from 'Modules/App/selectors';

export const getNotesEnabled = state => state.getIn(['note', 'notesEnabled']);
export const getLastSavedAt = state => state.getIn(['note', 'lastSavedAt']);
export const getNotes = state => state.getIn(['note', 'notes']);
export const getNote = (state, props) => getNotes(state).get(props.noteId);
export const getNoteByEventId = (state, props) => getNotes(state).find(note => note.eventId === props.event.iCalUID);
export const getNoteBody = state => state.getIn(['note', 'body']);
export const getSaveError = state => state.getIn(['note', 'saveError']);
export const getIsDeletingNote = state => state.getIn(['note', 'isDeletingNote']);
export const getIsFetchingNote = state => state.getIn(['note', 'isFetchingNote']);
export const getIsSavingNote = state => state.getIn(['note', 'isSavingNote']);
export const getIsDraft = createSelector(
  getQueryParameters,
  queryParameters => !queryParameters.has('eventId'),
);
export const getNotesWithEventIds = createSelector(
  getNotes,
  notes => new Set(notes.map(note => note.get('eventId')).valueSeq().filter(id => !!id)),
);
