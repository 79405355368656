import GrooveHTTPClient from '@groove-labs/groove-http-client';
import humps from 'humps';

import transformEvents from 'Utils/transformEvents';
import {
  fetchEventsSuccess, actionTypes,
} from 'Modules/Events/actions';
import { put } from 'redux-saga/effects';

export default function* fetchEvents({ instanceEventId }) {
  try {
    yield put({ type: actionTypes.FETCH_EVENTS.PROGRESS });
    const response = yield* GrooveHTTPClient.makeRequest({
      path: '/api/v2/notes/events/range',
      queryParameters: humps.decamelizeKeys({
        instanceEventId,
      }),
    });

    const transformedEvents = transformEvents(response.data.results);
    yield put(fetchEventsSuccess(transformedEvents));
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_EVENTS.FAILURE,
      error: { ...e },
    });
  }
}
