import {
  createActions, keyMirror,
} from 'action-utils';

export const actionTypes = createActions({
  APP: {
    ...keyMirror([
      'SET_LOCATION',
      'SET_CURRENT_USER_INFO',
    ]),
  },
}).APP;

export const setCurrentUserInfo = ({
  email, id,
}) => ({
  type: actionTypes.SET_CURRENT_USER_INFO,
  payload: {
    email,
    id,
  },
});

export const setLocation = location => ({
  type: actionTypes.SET_LOCATION,
  payload: location,
});
