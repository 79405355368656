import { listToOrderedMap } from '@groove-labs/groove-ui';
import { fromJS } from 'immutable';

import Note from 'Modules/Note/data/Note';

export default function transformGrooveMeetingNotes(notes) {
  let transformedNotes = fromJS(notes);
  transformedNotes = transformedNotes.map(note => new Note({
    id: note.get('id'),
    eventId: note.getIn(['relationships', 'linkedEvents', 'data', 0, 'uid']),
    createdAt: note.getIn(['attributes', 'createdDate']),
    updatedAt: note.getIn(['attributes', 'lastModifiedDate']),
    body: note.getIn(['attributes', 'textPreview']),
  }));

  return listToOrderedMap(transformedNotes, note => note.id);
}
