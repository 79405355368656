import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withStyles,
  Divider,
} from '@material-ui/core';
import { actions } from '@groove-labs/groove-ui';
import moment from 'moment';

import DayGroupDate from 'Modules/Events/components/DayGroupDate';
import Event from 'Modules/Events/containers/Event';
import {
  associateNoteToEvent,
  saveNote,
} from 'Modules/Note/actions';
import { getNoteId } from 'Modules/App/selectors';
import { getNoteBody } from 'Modules/Note/selectors';
import DraftCreatedLine from 'Modules/Note/components/DraftCreatedLine';
import {
  MEETING_DROPDOWN_UI_KEY_PATH,
  CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH,
} from 'Modules/Note/constants';

const { setProperty } = actions.ui;

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateContainer: {
    marginLeft: 25,
    minWidth: 60,
  },
  listItemContainer: {
    flexGrow: 1,
  },
  listItemGutter: {
    paddingLeft: 0,
  },
};

@connect(
  state => ({
    noteId: getNoteId(state),
    noteBody: getNoteBody(state),
  }),
  {
    associateNoteToEvent,
    saveNote,
    setProperty,
  },
)
@withStyles(styles)
export default class MeetingDropdownDayGroup extends Component {
  static propTypes = {
    associateNoteToEvent: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    setProperty: PropTypes.func.isRequired,
    noteIdAndPosition: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    noteIdAndPosition: {
      noteId: null,
      position: null,
    },
  }

  handleEventClick = event => () => {
    this.props.setProperty({
      uiKeyPath: MEETING_DROPDOWN_UI_KEY_PATH,
      data: false,
    });
    this.props.setProperty({
      uiKeyPath: CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH,
      data: false,
    });
    const {
      noteBody,
      saveNote,
    } = this.props;
    saveNote({
      selectedEventId: event.iCalUID,
      body: noteBody,
    });
  }

  groupIncludesNoteIdForLine() {
    const {
      events,
      noteIdAndPosition,
    } = this.props;
    return events.map(event => event.id).includes(noteIdAndPosition.noteId);
  }

  draftCreatedProps() {
    const { note } = this.props;
    return {
      text: `Draft Created ${moment(note.createdAt).format('h:mm A')}`,
    };
  }

  item() {
    const {
      events,
      noteIdAndPosition,
    } = this.props;
    return events.map((event) => {
      const eventProps = {
        event,
        handleClick: this.handleEventClick(event),
        key: event.id,
      };
      const {
        noteId,
        position,
      } = noteIdAndPosition;
      return (
        <div key={event.id}>
          <Event {...eventProps} />
          {noteId === event.id && position === 'between' ? <DraftCreatedLine {...this.draftCreatedProps()} /> : null}
        </div>
      );
    });
  }

  render() {
    const {
      classes,
      day,
      noteIdAndPosition,
    } = this.props;
    const { position } = noteIdAndPosition;
    return (
      <div>
        {this.groupIncludesNoteIdForLine() && position === 'start' ? <DraftCreatedLine {...this.draftCreatedProps()} /> : null}
        <div className={classes.root}>
          <div className={classes.dateContainer}>
            <DayGroupDate day={day} />
          </div>
          <div className={classes.listItemContainer}>
            {this.item()}
          </div>
        </div>
        {this.groupIncludesNoteIdForLine() && position === 'end' ? <DraftCreatedLine {...this.draftCreatedProps()} /> : <Divider />}
      </div>
    );
  }
}
