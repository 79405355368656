import {
  FloatingActionButton, RIT,
} from '@groove-labs/groove-ui';
import {
  AppBar, Divider, Toolbar, Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectNote } from 'Modules/Events/actions';
import {
  getIsInDetailView,
  getIsEditingNote,
} from 'Modules/App/selectors';
import {
  LIST_HEADER_HEIGHT, MAIN_TOOLBAR_HEIGHT, WAVY_NAVY,
} from 'Modules/App/constants';
import DetailViewBackButton from 'Modules/App/containers/DetailViewBackButton';
import { getNotesEnabled } from 'Modules/Note/selectors';


const styles = {
  root: {
    height: MAIN_TOOLBAR_HEIGHT,
    boxShadow: 'none',
    backgroundColor: WAVY_NAVY,
  },
  '@media (min-width: 0px)': {
    toolbar: {
      minHeight: MAIN_TOOLBAR_HEIGHT,
      height: MAIN_TOOLBAR_HEIGHT,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
    },
    listHeader: {
      LIST_HEADER_HEIGHT,
    },
  },
  listHeader: {
    background: 'white',
    display: 'flex',
    minHeight: LIST_HEADER_HEIGHT,
    transition: 'opacity 0.3s, min-height 0.3s',
  },
  titleTypography: {
    fontSize: 20,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    color: '#5C5C5C',
    fontSize: 14,
  },
  dateTypography: {
    paddingLeft: 8,
    paddingRight: 176,
  },
  transparentAndHeightless: {
    minHeight: 0,
    opacity: 0,
  },

  floatingActionButton: {
    position: 'absolute',
    top: -28,
    right: 28,
  },
};

@connect(
  state => ({
    isInDetailView: getIsInDetailView(state),
    isEditingNote: getIsEditingNote(state),
    notesEnabled: getNotesEnabled(state),
  })
  ,
  {
    selectNote,
  },
)
@withStyles(styles)
export default class Header extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isEditingNote: PropTypes.bool.isRequired,
    isInDetailView: PropTypes.bool.isRequired,
    notesEnabled: PropTypes.bool.isRequired,
    selectNote: PropTypes.func.isRequired,
  };

  handleNewNote = () => {
    this.props.selectNote({ noteId: 'new' });
  }

  renderMasterViewTitle = () => 'Meeting Notes'
  renderDetailViewTitle = () => (this.props.isEditingNote ? 'Edit Meeting Note' : 'New Meeting Note')

  render() {
    const {
      classes,
      isInDetailView,
      notesEnabled,
    } = this.props;

    return (
      <AppBar
        color="primary"
        className={classes.root}
      >
        <Toolbar className={classes.toolbar}>
          <DetailViewBackButton />
          <Typography
            variant="title"
            color="inherit"
            className={classes.titleTypography}
          >
            {RIT(!isInDetailView, this.renderMasterViewTitle)}
            {RIT(isInDetailView, this.renderDetailViewTitle)}
          </Typography>
        </Toolbar>
        {notesEnabled && (
          <Toolbar className={cx(classes.toolbar, classes.listHeader, {
            [classes.transparentAndHeightless]: isInDetailView,
          })}
          >
            <Typography
              variant="button"
              className={cx(classes.typography, classes.dateTypography)}
            >
              Date
            </Typography>
            <Typography
              variant="button"
              className={classes.typography}
            >
              Notes
            </Typography>
            <div
              className={classes.floatingActionButton}
            >
              <Tooltip
                title="Create New Note"
              >
                <FloatingActionButton
                  onClick={this.handleNewNote}
                />
              </Tooltip>
            </div>
          </Toolbar>
        )}
        <Divider className={cx({
          [classes.transparentAndHeightless]: isInDetailView,
        })}
        />
      </AppBar>
    );
  }
}
