import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
  },
};

/* eslint-disable max-len */
const NoteIcon = ({ classes }) => (
  <svg
    className={classes.root}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Meeting-Notes"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Meeting-notes---index"
        transform="translate(-928 -726)"
      >
        <g
          id="Group-Copy"
          transform="translate(919 717)"
        >
          <g
            id="ic_note-outline"
            transform="translate(6 6)"
          >
            <polygon
              id="Bounds"
              fillOpacity="0.1"
              fill="#F00"
              opacity="0"
              transform="rotate(-90 12 12)"
              points="0 0 24 0 24 24 0 24"
            />
            <g
              id="note-outline"
              transform="translate(3 3)"
              fill="#000"
            >
              <path
                d="M11,7 L16.5,7 L11,1.5 L11,7 Z M2,0 L12,0 L18,6 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.89,18 0,17.1 0,16 L0,2 C0,0.89 0.89,0 2,0 Z M2,2 L2,16 L16,16 L16,9 L9,9 L9,2 L2,2 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
NoteIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(NoteIcon);
