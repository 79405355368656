import {
  createActions, keyMirror, standardActions,
} from 'action-utils';

export const actionTypes = createActions({
  EVENTS: {
    ...keyMirror([
      'SELECT_NOTE',
      'GO_BACK_TO_EVENTS',
    ]),
    FETCH_EVENTS: standardActions(),
  },
}).EVENTS;

export const fetchEventsBegin = () => ({
  type: actionTypes.FETCH_EVENTS.BEGIN,
});

export const fetchEventsSuccess = payload => ({
  type: actionTypes.FETCH_EVENTS.SUCCESS,
  payload,
});

export const goBackToEvents = () => ({
  type: actionTypes.GO_BACK_TO_EVENTS,
});

export const selectNote = ({
  noteId, eventId,
}) => ({
  type: actionTypes.SELECT_NOTE,
  payload: {
    noteId,
    eventId,
  },
});
