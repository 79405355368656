import shortid from 'shortid';
import queryString from 'query-string';
import { eventChannel } from 'redux-saga';

import { take } from 'redux-saga/effects';
import { VisualforceError } from 'Modules/Note/errors';


export default class Client {
  configure({
    visualforceUrl,
    communityUrl,
    pod,
    myDomain,
    sfdcAccessToken,
    xsrfToken,
  }) {
    this.visualforceUrl = visualforceUrl;
    this.communityUrl = communityUrl;
    this.pod = pod;
    this.myDomain = myDomain;
    this.sfdcAccessToken = sfdcAccessToken;
    this.xsrfToken = xsrfToken;
  }


  createVisualforceUrl(apexPage, urlParameters = '') {
    const urlQueryString = urlParameters ? `?${queryString.stringify(urlParameters)}` : '';

    // TODO: @vijaypemmaraju remove this customer-specific code ASAP
    if (this.myDomain === 'datadog') {
      return `https://datadog--dascoopcomposer.visualforce.com/apex/${apexPage}${urlQueryString}`;
    }

    if (this.visualforceUrl) {
      return `${this.visualforceUrl}${apexPage}${urlQueryString}`;
    }
    if (this.communityUrl) {
      return `${this.communityUrl}DaScoopComposer__${apexPage}${urlQueryString}`;
    }
    if (this.myDomain !== '') {
      return `https://${this.myDomain}--dascoopcomposer.${this.pod}.visual.force.com/apex/${apexPage}${urlQueryString}`;
    }

    return `https://dascoopcomposer.${this.pod}.visual.force.com/apex/${apexPage}${urlQueryString}`;
  }

  * call(page, parameters) {
    const authHeaders = {};
    if (typeof this.sfdcAccessToken !== 'undefined') {
      authHeaders.Authorization = `Bearer ${this.sfdcAccessToken}`;
    }
    const id = shortid.generate();
    window.postMessage({
      type: 'FETCH',
      payload: {
        url: this.createVisualforceUrl(page, parameters),
        headers: Object.assign(authHeaders, {
          xsrfToken: `OAuth ${this.xsrfToken}`,
          'X-Groove-XSRF': true,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        id,
      },
      meta: {
        source: 'notes',
      },
    }, window.origin);

    const channel = eventChannel((emitter) => {
      const handler = ({
        data, origin,
      }) => {
        if (origin !== window.origin) {
          return;
        }
        if (!data) {
          return;
        }
        if (!data.payload) {
          return;
        }
        if (data.type !== 'FETCH_RESPONSE') {
          return;
        }

        if (data.payload.id === id) {
          emitter(data.payload.response);
        }
        window.removeEventListener('message', handler);
      };

      window.addEventListener('message', handler);

      return () => window.removeEventListener('message', handler);
    });
    const response = yield take(channel);

    if (response.errors) {
      throw new VisualforceError(response.errors);
    }

    return response;
  }
}
