import GrooveHTTPClient from '@groove-labs/groove-http-client';
import Raven from 'raven-js';
import { eventChannel } from 'redux-saga';

import VisualforceClient from 'Utils/VisualforceClient';
import {
  all,
  fork,
  call,
  delay,
  race,
  take,
  put,
} from 'redux-saga/effects';
import historySaga from 'Modules/App/sagas/history';
import {
  setCurrentUserInfo,
} from 'Modules/App/actions';

const createIncomingActionEventChannel = () => (
  eventChannel((emitter) => {
    const handler = (message) => {
      // Ensure the message came from this same window
      if (message.source !== window) {
        return;
      }

      // Ensure the message is a FSA from Chrome extension
      const action = message.data;
      if (!action) {
        return;
      }

      emitter(action);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  })
);

function* waitForExtensionToken() {
  const channel = createIncomingActionEventChannel();

  while (true) {
    const {
      type,
      payload,
    } = yield take(channel);

    if (type === 'HYDRATE_GROOVE_EXTENSION_AUTH') {
      // yield put(setExtensionAuth(payload));
      return payload;
    }
  }
}

export function* upstart() {
  const {
    extensionAuthenticationData,
  } = yield race({
    extensionAuthenticationData: call(waitForExtensionToken),
    timeout: delay(5000),
  });

  if (!extensionAuthenticationData) {
    // throw new UnableToGetExtensionAuthenticationDataError();
  }

  const {
    grooveEngineRootUrl,
    grooveEngineUserEmail,
    grooveEngineUserId,
    grooveExtensionToken,

    visualforceUrl,
    communityUrl,
    pod,
    myDomain,
    sfdcAccessToken,
    xsrfToken,
  } = extensionAuthenticationData;

  Raven.setUserContext({
    ...(grooveEngineUserEmail && { email: grooveEngineUserEmail }),
    ...(grooveEngineUserId && { userId: grooveEngineUserId }),
  });

  yield put(setCurrentUserInfo({
    id: grooveEngineUserId,
    email: grooveEngineUserEmail,
  }));

  window.GROOVE_ENGINE_BASE_URL = grooveEngineRootUrl || window.GROOVE_ENGINE_BASE_URL;

  GrooveHTTPClient.configure({
    baseURL: window.GROOVE_ENGINE_BASE_URL,
    defaultHeaders: {
      grooveAuthorization: grooveExtensionToken,
    },
  });

  VisualforceClient.configure({
    visualforceUrl,
    communityUrl,
    pod,
    myDomain,
    sfdcAccessToken,
    xsrfToken,
  });
}

export default function* root() {
  yield all([
    fork(historySaga),
  ]);
}
