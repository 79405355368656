import Raven from 'raven-js';
import SegmentAnalytics from 'analytics-node';
import {
  isFunction,
} from 'lodash-es';

import {
  call,
  select,
} from 'redux-saga/effects';
import {
  getCurrentUserData,
} from 'Modules/App/selectors';

const analytics = new SegmentAnalytics(process.env.SEGMENT_JS_API_KEY);

const trackEvent = (...params) => {
  try {
    if (analytics.track && isFunction(analytics.track)) {
      analytics.track(...params);
    }
  } catch (e) {
    Raven.captureMessage('Segment tracking error', {
      extra: { e },
      level: 'warning',
    });
  }
};

const printTrackingStarted = () => console.debug('[groove-analytics] track event started'); // eslint-disable-line no-console

const printTrackingFinished = () => console.debug('[groove-analytics] track event finished'); // eslint-disable-line no-console

const handleTrackingError = ({ error }) => {
  console.debug('[groove-analytics] track event error', error); // eslint-disable-line no-console
  Raven.captureMessage('Event tracking error', {
    extra: { error },
    level: 'warning',
  });
};

export function createSafeActionHandler(saga) {
  return function* safeHandler(...args) {
    if (process.env.DEPLOY_ENV !== 'production') {
      return;
    }

    try {
      printTrackingStarted();
      yield call(saga, ...args);
    } catch (error) {
      handleTrackingError({ error });
    } finally {
      printTrackingFinished();
    }
  };
}

export function* basicEventTrackingHandler({
  featureCategory, eventName, properties = {},
}) {
  yield call(createSafeActionHandler(function* handler() {
    const currentUserData = yield select(getCurrentUserData);

    trackEvent({
      userId: currentUserData.get('id'),
      featureCategory,
      event: eventName,
      properties: {
        ...properties,
        ui: 'notes',
      },
    });
  }));
}
