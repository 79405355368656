
import {
  call,
  put,
} from 'redux-saga/effects';
import {
  actionTypes,
  setNotesEnabled,
} from 'Modules/Note/actions';
import VisualforceClient from 'Utils/VisualforceClient';
import transformGrooveMeetingNotes from 'Utils/transformGrooveMeetingNotes';
import { VisualforceError } from 'Modules/Note/errors';
import { pushQueryParameters } from 'Utils/history';

export default function* handleFetchAllNotes() {
  yield put({ type: actionTypes.FETCH_ALL_NOTES.PROGRESS });

  try {
    const response = yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
      mode: 'get',
      all: true,
    });
    const notes = transformGrooveMeetingNotes(response.data);
    yield put({
      type: actionTypes.FETCH_ALL_NOTES.SUCCESS,
      payload: notes,
    });
  } catch (e) {
    if (e.name === VisualforceError.name) {
      yield put({
        type: actionTypes.FETCH_ALL_NOTES.FAILURE,
        payload: e.errors,
      });

      if (e.errors && e.errors.length > 0 && e.errors[0].status === 501) {
        yield put(setNotesEnabled(false));
        yield call(pushQueryParameters, {});
      }
    } else {
      throw e;
    }
  }
}
