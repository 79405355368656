import qs from 'query-string';
import { eventChannel } from 'redux-saga';

import {
  take, put,
} from 'redux-saga/effects';
import history from 'Utils/history';
import { setLocation } from 'Modules/App/actions';

const hydrateQueryParameters = location => ({
  ...location,
  queryParameters: qs.parse(location.search),
});

export default function* root() {
  const historyChannel = eventChannel((emitter) => {
    let initialLocation;

    if (typeof history.getCurrentLocation === 'function') {
      initialLocation = history.getCurrentLocation();
    } else {
      initialLocation = history.location;
    }

    if (initialLocation) {
      emitter(initialLocation);
    }

    return history.listen((location) => {
      emitter(location);
    });
  });

  yield put(setLocation(hydrateQueryParameters(location)));

  while (true) {
    const location = yield take(historyChannel);

    yield put(setLocation(hydrateQueryParameters(location)));
  }
}
