import {
  call,
  put,
  select,
  delay,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/Note/actions';
import { getQueryParameters } from 'Modules/App/selectors';
import VisualforceClient from 'Utils/VisualforceClient';
import { VisualforceError } from 'Modules/Note/errors';
import transformParagraphsToNewlines from 'Utils/transformers/transformParagraphsToNewlines';
import b64DecodeUnicode from 'Utils/b64DecodeUnicode';

export default function* handleFetchNote() {
  yield put({ type: actionTypes.FETCH_NOTE.PROGRESS });
  const queryParameters = yield select(getQueryParameters);

  const noteId = queryParameters.get('noteId');
  if (noteId !== 'new') {
    try {
      const response = yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
        mode: 'get',
        note_id: noteId,
      });

      const content = b64DecodeUnicode(response.data.attributes.content);

      const transformedContent = transformParagraphsToNewlines(content);
      // the content comes back as HTML, so this is the simplest
      // way to convert it back to plaintext
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = transformedContent;


      yield put({
        type: actionTypes.FETCH_NOTE.SUCCESS,
        payload: tempContainer.textContent,
      });
    } catch (e) {
      if (e.name === VisualforceError.name) {
        yield put({
          type: actionTypes.FETCH_NOTE.FAILURE,
          payload: e.errors,
        });
      } else {
        throw e;
      }
    }
  } else {
    // there needs to be an arbitrary delay so that the render
    // cycle can complete and the input unmounts (so it can immediately
    // remount and populate itself via defaultValue)
    yield delay(100);
    yield put({
      type: actionTypes.FETCH_NOTE.SUCCESS,
      payload: '',
    });
  }
}
