import momentTimezone from 'moment-timezone';

import Event from 'Modules/Events/data/Event';

const transformEvents = events => (
  events.map((event) => {
    const start = event.start && momentTimezone(event.start.dateTime || event.start.date);
    const end = event.end && momentTimezone(event.end.dateTime || event.end.date);

    // this is gcal's way of telling us that an event is all-day
    const isAllDay = event.start && !!event.start.date;

    // events that span more than a day should show up at the top with the all day events
    const isGreaterThan24Hours = start && end && momentTimezone.duration(end.diff(start)).asHours() > 24;
    return (
      new Event({
        id: event.id,
        iCalUID: event.iCalUID,
        title: event.summary,
        userId: event.userId,
        ...(event.start && { start }),
        ...(event.end && { end }),
        isAllDay,
        isGreaterThan24Hours,
      })
    );
  })
);

export default transformEvents;
