import { RIT } from '@groove-labs/groove-ui';
import React, {
  Component,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {
  Divider,
  CircularProgress,
  Collapse,
} from '@material-ui/core';

import {
  getEventsAndNoteDraftsGroupedByDay,
  getIsFetchingEvents,
} from 'Modules/Events/selectors';
import EventsAndNoteDraftsDayGroup from 'Modules/Events/components/EventsAndNoteDraftsDayGroup';
import {
  APP_BAR_HEIGHT, SCROLLABLE_VIEW,
} from 'Modules/App/constants';
import Event from 'Modules/Events/data/Event';

const styles = {
  root: {
    width: '50%',
  },
  list: {
    overflow: 'scroll',
    maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
    position: 'relative',
    top: APP_BAR_HEIGHT,
  },
  loader: {
    width: '100%',
    height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fadeOut: {
    left: 0,
    width: '100%',
    bottom: 0,
    position: 'sticky',
    height: 50,
    background: 'linear-gradient(to bottom, rgba(255,255,255,0.25) 0%,rgba(255,255,255,1) 100%)',
  },
  collapseContainer: {
    height: '100vh',
  },
};

@connect(
  state => ({
    eventsAndNoteDraftsGroupedByDay: getEventsAndNoteDraftsGroupedByDay(state),
    isFetchingEvents: getIsFetchingEvents(state),
  }),
  {
  },
)
@withStyles(styles)
export default class ModuleRoot extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    eventsAndNoteDraftsGroupedByDay: ImmutablePropTypes.orderedMapOf(
      ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf(Event)).isRequired,
      PropTypes.string.isRequired,
    ).isRequired,
    isFetchingEvents: PropTypes.bool.isRequired,
  };

  renderLoader = () => <div className={this.props.classes.loader}><CircularProgress /></div>

  renderEvents = () => {
    const {
      classes, eventsAndNoteDraftsGroupedByDay,
    } = this.props;
    return (
      <Collapse
        in
        classes={{ container: classes.collapseContainer }}
      >
        <List
          className={classes.list}
          id={SCROLLABLE_VIEW}
        >
          {eventsAndNoteDraftsGroupedByDay.map((eventsAndNoteDrafts, day) => (
            <Fragment
              // eslint-disable-next-line react/no-array-index-key
              key={day}
            >
              <EventsAndNoteDraftsDayGroup
                eventsAndNoteDrafts={eventsAndNoteDrafts}
                day={day}
              />
              <Divider />
            </Fragment>
          )).valueSeq()}
          <div className={classes.fadeOut} />
        </List>
      </Collapse>
    );
  }

  render() {
    const {
      classes, isFetchingEvents,
    } = this.props;
    return (
      <div className={classes.root}>
        {RIT(isFetchingEvents, this.renderLoader)}
        {RIT(!isFetchingEvents, this.renderEvents)}
      </div>
    );
  }
}
