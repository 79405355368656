import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { MEETING_SCROLLABLE_VIEW } from 'Modules/Note/constants';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
  },
  textRoot: {
    paddingRight: 15,
    paddingLeft: 15,
    color: '#DB4437',
    fontSize: 12,
    fontStyle: 'italic',
    paddingTop: 4,
    width: 400,
    display: 'flex',
    justifyContent: 'center',
  },
};

const lineProps = {
  style: {
    stroke: '#DB4437',
    strokeWidth: 2,
  },
  x1: '0%',
  x2: '100%',
  y1: '50%',
  y2: '50%',
};
const svgProps = {
  height: 20,
  width: '100%',
};
const SvgLine = () => (
  <svg {...svgProps}>
    <line {...lineProps} />
  </svg>
);

class DraftCreatedLine extends React.Component {
  componentDidMount() {
    this.ref.scrollIntoView(true);
    const menuElement = document.getElementById(MEETING_SCROLLABLE_VIEW);
    menuElement.scrollBy(0, menuElement.offsetHeight * -0.66);
  }

  assignRef = (ctx) => {
    this.ref = ctx;
  }

  render() {
    const {
      classes, text,
    } = this.props;
    return (
      <div
        className={classes.root}
        ref={this.assignRef}
      >
        <SvgLine />
        <Typography className={classes.textRoot}>
          {text}
        </Typography>
        <SvgLine />
      </div>
    );
  }
}


DraftCreatedLine.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  text: PropTypes.string,
};

DraftCreatedLine.defaultProps = {
  text: 'Draft Created',
};

export default withStyles(styles)(DraftCreatedLine);
